import React, { useCallback, useMemo, useState } from 'react'
import {
    Button,
    Chip,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material'

import type * as schema from 'zapatos/schema'

import type { Log } from '@a10base/common/types/index.js'
import {
    SearchParams,
    trpcBase,
    updateUrlQuery,
    urlQueryUpdater,
} from '@a10base/frontend/util/index.js'
import {
    AsyncTable,
    BaseTableColumn,
    PageTitleRow,
    SearchBox,
    useAsyncTable,
    useUrlTableState,
} from '@a10base/frontend/components/index.js'
import { getFilters, isValidDateStr, parseNumber, truncate } from '@a10base/common/index.js'
import { subMinutes } from 'date-fns'
import { useSelector } from 'react-redux'
import { useSearchParamChanges } from '@a10base/frontend/hooks/misc.js'

export const LogsPage: React.FC = () => {
    const [selected, setSelected] = useState<Log | undefined>(undefined)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const searchParams = useSelector<any>(state => state.url.searchParams) as SearchParams
    const tableState = useUrlTableState(searchParams, 'logs', 25)
    useSearchParamChanges(['level', 'maxTs', 'message', 'clientId', 'pid'], () =>
        tableState.onChangePage(0)
    )
    const logLevels = searchParams.array['level'] as schema.log_level[]
    const maxTs = searchParams.first['maxTs']
    const message = searchParams.first['message']
    const clientId = searchParams.first['clientId']
    const pid = searchParams.first['pid']
    const loadLogs = useCallback(
        (offset: number, limit: number, searchText?: string) => {
            return trpcBase.admin.item.getItems.query({
                table: 'log',
                search: searchText,
                offset,
                limit,
                filters: getFilters(
                    ...(logLevels ?? []).map(v => `level='${v}'`),
                    maxTs && isValidDateStr(maxTs) && `ts<='${maxTs}'`,
                    message && `message='${message}'`,
                    clientId && clientId.trim() && `client_id='${clientId.trim()}'`,
                    pid && parseNumber(pid) && `pid=${pid.trim()}`
                ),
                orderBy: 'ts',
                orderDir: 'DESC',
            }) as unknown as Promise<Log[]>
        },
        [logLevels, maxTs, message, pid, clientId]
    )
    const tableProps = useAsyncTable(loadLogs, tableState)
    const columns = useMemo<BaseTableColumn<Log>[]>(() => tableColumns(setSelected), [])
    return (
        <Stack useFlexGap direction="column" spacing={1}>
            <PageTitleRow title="Logs" />
            <Stack
                direction="row"
                flexWrap="wrap"
                spacing={1}
                useFlexGap
                sx={{ p: 2, border: '1px dotted lightgrey' }}
            >
                <LogLevelSelector value={logLevels ?? []} onChange={urlQueryUpdater('level')} />
                <SearchBox
                    label="Message"
                    value={message}
                    onSearch={urlQueryUpdater('message')}
                    searchOnBlur
                    sx={{ width: '20rem' }}
                />
                <SearchBox
                    label="pid"
                    value={pid}
                    onSearch={urlQueryUpdater('pid')}
                    searchOnBlur
                    sx={{ width: '4rem' }}
                />
                <SearchBox
                    label="client id"
                    value={clientId}
                    onSearch={urlQueryUpdater('clientId')}
                    searchOnBlur
                    sx={{ width: '4rem' }}
                />
                <Stack direction="row" useFlexGap alignItems="center">
                    <SearchBox
                        label="max ts"
                        value={maxTs}
                        onSearch={urlQueryUpdater('maxTs')}
                        searchOnBlur
                        sx={{ width: '16rem' }}
                    />
                    <Button
                        variant="text"
                        onClick={() => updateUrlQuery({ maxTs: new Date().toISOString() })}
                    >
                        now
                    </Button>
                </Stack>

                <Button
                    onClick={() => {
                        updateUrlQuery({
                            level: undefined,
                            maxTs: subMinutes(new Date(), 5).toISOString(),
                            message: undefined,
                            trId: undefined,
                            pid: undefined,
                            uid: undefined,
                            cid: undefined,
                        })
                    }}
                >
                    Latest
                </Button>
                <Button
                    onClick={() => {
                        updateUrlQuery({
                            level: ['warn', 'error', 'fatal'],
                            maxTs: undefined,
                            message: undefined,
                            trId: undefined,
                            pid: undefined,
                            uid: undefined,
                            cid: undefined,
                        })
                    }}
                >
                    Latest problems
                </Button>
            </Stack>
            <AsyncTable size="small" columns={columns} {...tableProps} {...tableState} searchable />
            {selected && (
                <Dialog open={true} onClose={() => setSelected(undefined)}>
                    <DialogContent>
                        <pre>{JSON.stringify(selected.attributes, null, 4)}</pre>
                    </DialogContent>
                </Dialog>
            )}
        </Stack>
    )
}

interface LogLevelSelectorProps {
    value: schema.log_level[]
    onChange: (value: schema.log_level[]) => void
}
const LogLevelSelector: React.FC<LogLevelSelectorProps> = ({ value, onChange }) => {
    const levels: schema.log_level[] = ['debug', 'info', 'warn', 'error', 'fatal']

    const handleChange = (event: SelectChangeEvent<schema.log_level[]>) => {
        const {
            target: { value },
        } = event
        onChange(
            // On autofill we get a stringified value.
            typeof value === 'string' ? (value.split(',') as schema.log_level[]) : value
        )
    }
    return (
        <FormControl sx={{ width: 300 }}>
            <InputLabel id="log-level-selector">Log level</InputLabel>
            <Select
                labelId="log-level-selector"
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label="Log level" />}
            >
                {levels.map(level => (
                    <MenuItem key={level} value={level}>
                        {level}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

// interface LogsModalProps {
//     filters: string[]
//     onClose: () => void
// }
// export const LogsModal: React.FC<LogsModalProps> = ({ filters, onClose }) => {
//     const [selected, setSelected] = useState<Log | undefined>(undefined)
//     const tableState = useTableState(25)
//     const loadLogs = useCallback(
//         (offset: number, limit: number) => {
//             return trpc().admin.item.getItems.query({
//                 table: 'log',
//                 offset,
//                 limit,
//                 filters,
//             }) as Promise<Log[]>
//         },
//         [filters]
//     )
//     const tableProps = useAsyncTable(loadLogs, tableState)
//     const columns = useMemo<BaseTableColumn<Log>[]>(() => tableColumns(setSelected), [])

//     return (
//         <Modal onClose={onClose}>
//             {!selected && (
//                 <div>
//                     <small>Filter: {filters.join(' & ')}</small>
//                     <AsyncTable columns={columns} {...tableProps} {...tableState} />
//                 </div>
//             )}
//             {selected && (
//                 <div>
//                     <button onClick={() => setSelected(undefined)}>Close</button>
//                     <pre onClick={() => setSelected(undefined)}>
//                         {JSON.stringify(selected.attributes, null, 4)}
//                     </pre>
//                     <button onClick={() => setSelected(undefined)}>Close</button>
//                 </div>
//             )}
//         </Modal>
//     )
// }

function tableColumns(setSelected: (log: Log) => void): BaseTableColumn<Log>[] {
    return [
        { id: 'ts', header: 'Time', render: v => v.ts },
        { id: 'level', header: 'Level', render: renderLevel },
        { id: 'message', header: 'Message', render: v => truncate(v.message, 80) },
        {
            id: 'journal_id',
            header: 'Source',
        },
        {
            id: 'context',
            header: 'Context',
            render: v => v.context,
        },
        {
            id: 'details',
            header: 'Details',
            render: v => {
                if (v.error_msg) {
                    return truncate(v.error_msg, 60)
                }
                if (v.method) {
                    return truncate(`${v.method} ${v.path}`, 60)
                }
                return ''
            },
        },
        {
            id: 'actions',
            header: '',
            render: v => (
                <Button onClick={() => setSelected(v)} variant="text" size="small">
                    raw
                </Button>
            ),
        },
    ]
}

function renderLevel(log: Log): React.ReactNode {
    switch (log.level) {
        case 'debug':
            return <Chip color="info" label="debug" size="small" variant="outlined" />
        case 'info':
            return <Chip color="info" label="info" size="small" variant="outlined" />
        case 'warn':
            return <Chip color="warning" label="warning" size="small" variant="outlined" />
        case 'error':
            return <Chip color="error" label="error" size="small" variant="filled" />
        case 'fatal':
            return <Chip color="error" label="fatal" size="small" variant="filled" />
    }
}
