import React, { useCallback, useMemo, useState } from 'react'
import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'

import { AsyncButton, PageTitleRow } from '@a10base/frontend/components/index.js'
import {
    trpcBase,
    logger,
    serverData,
    getStrFromLocalStorage,
    setStrToLocalStorage,
    navigateTo,
} from '@a10base/frontend/util/index.js'
import { useAsyncFn, useAsyncFn1Param } from '@a10base/frontend/hooks/async-function.js'

export const AdminMiscToolsPage: React.FC = () => {
    const [modal, setModal] = useState<
        'slack-test' | 'debug-console' | 'server-data' | 'local-logs' | 'echo' | undefined
    >(undefined)
    return (
        <Stack direction="column" spacing={1} useFlexGap>
            <PageTitleRow title="Misc tools" />

            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                <Button onClick={() => navigateTo('/email-templates')}>Email templates</Button>
                <Button onClick={() => navigateTo('/logs')}>Logs</Button>

                <Button onClick={() => setModal('slack-test')}>Slack test</Button>
                <Button onClick={() => setModal('debug-console')}>Debug console</Button>
                <Button onClick={() => setModal('server-data')}>Server data</Button>
                <Button onClick={() => setModal('local-logs')}>Local logs</Button>
                <Button onClick={() => setModal('echo')}>Echo endpoint</Button>

                <Button onClick={() => window.location.assign(window.location.origin)}>
                    Reload page
                </Button>

                <Button
                    onClick={() => {
                        throw new Error(`Manually triggered test error`)
                    }}
                >
                    Throw client error
                </Button>
            </Stack>

            {modal !== undefined && (
                <Dialog open={true} onClose={() => setModal(undefined)} maxWidth="lg">
                    <DialogContent>
                        {modal === 'slack-test' && <SlackTestTool />}
                        {modal === 'debug-console' && <ToggleDebugConsole />}
                        {modal === 'echo' && <EchoEndpoint />}
                        {modal === 'server-data' && (
                            <Stack>
                                <Typography variant="h5">Server Data</Typography>
                                <pre>{JSON.stringify(serverData, null, 4)}</pre>
                            </Stack>
                        )}
                        {modal === 'local-logs' && (
                            <Stack>
                                <Typography variant="h5">Latest local log messages</Typography>
                                <pre>{JSON.stringify(logger.getLatestLogMessages(), null, 4)}</pre>
                            </Stack>
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </Stack>
    )
}

const SlackTestTool: React.FC = () => {
    const sendAction = useAsyncFn(trpcBase.admin.misc.sendTestSlackMessages.mutate)
    return (
        <Stack>
            <Typography variant="h5">Slack test</Typography>
            <AsyncButton {...sendAction}>Send test messages to Slack channels</AsyncButton>
        </Stack>
    )
}

const ToggleDebugConsole: React.FC = () => {
    const debugConsoleOpen = getStrFromLocalStorage('DEBUG_CONSOLE') === 'true'
    const toggle = useCallback(() => {
        const showLogConsole = getStrFromLocalStorage('DEBUG_CONSOLE')
        setStrToLocalStorage('DEBUG_CONSOLE', showLogConsole === 'true' ? 'false' : 'true')
        window.location.assign('/')
    }, [])
    return (
        <Stack>
            <Typography variant="h5">Debug console</Typography>
            <Button onClick={toggle}>
                {debugConsoleOpen ? 'Close debug console' : 'Open debug console'}
            </Button>
        </Stack>
    )
}

const EchoEndpoint: React.FC = () => {
    const [input, setInput] = useState<string>('{}')
    const parsedInput = useMemo(() => {
        try {
            return JSON.parse(input)
        } catch {
            return undefined
        }
    }, [input])
    const [result, setResult] = useState<object | undefined>(undefined)
    const sendAction = useAsyncFn1Param(
        trpcBase.admin.misc.echo.mutate,
        parsedInput ?? {},
        setResult
    )
    return (
        <Stack direction="column" spacing={2} useFlexGap>
            <Typography variant="h5">Send data to echo endpoint</Typography>
            <textarea value={input} rows={10} onChange={e => setInput(e.target.value)}></textarea>
            <AsyncButton {...sendAction}>Send</AsyncButton>
            {result && <pre>{JSON.stringify(result, null, 4)}</pre>}
        </Stack>
    )
}
