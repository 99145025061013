import React, { useEffect, useMemo } from 'react'
import { Stack } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import _ from 'lodash'

import { firstX, nop, objectValues, unixTime } from '@a10base/common/misc.js'
import {
    AsyncButton,
    BaseTable,
    BaseTableColumn,
    PageTitleRow,
} from '@a10base/frontend/components/index.js'
import { useAsyncFn1 } from '@a10base/frontend/hooks/index.js'
import { trpc } from '@a10yll/frontend/util/index.js'
import { Application, Scoring } from '@a10yll/common/index.js'
import { itemActions, useAppDispatch, useAppSelector } from '@a10yll/frontend/redux/index.js'
import { ItemDict } from '@a10base/common/index.js'
import { formatDateTime } from '@a10base/frontend/util/date-time.js'

export function ScoringPage() {
    const scoringDict = useAppSelector(state => state.item.items['scoring'] as ItemDict<Scoring>)
    const applicationDict = useAppSelector(
        state => state.item.items['application'] as ItemDict<Application>
    )
    const dispatch = useAppDispatch()

    const loadMore = useAsyncFn1(async (beforeId?: number) => {
        const scorings = await trpc.admin.scoring.loadScorings.query({ beforeId })
        dispatch(itemActions.upsertItems('scoring', scorings.scorings))
        dispatch(itemActions.upsertItems('application', scorings.applications))
    })

    // Initial load
    useEffect(() => {
        trpc.admin.scoring.loadScorings
            .query({})
            .then(scorings => {
                dispatch(itemActions.upsertItems('scoring', scorings.scorings))
                dispatch(itemActions.upsertItems('application', scorings.applications))
            })
            .catch(nop)
    }, [dispatch])

    const [rows, oldestScoringId] = useMemo(() => {
        const scorings = objectValues(scoringDict)
        const applications = objectValues(applicationDict)
        const scoringsByClientId = _.groupBy(scorings, v => v.client_id)
        const applicationsByClientId = _.groupBy(applications, v => v.client_id)
        const clientIds = Object.keys(scoringsByClientId)
        const rows = clientIds.map(clientId => {
            return {
                clientId,
                scorings: _.sortBy(scoringsByClientId[clientId] ?? [], v => -v.id),
                applications: applicationsByClientId[clientId] ?? [],
            }
        })
        rows.sort((a, b) => {
            const latestScoringA = firstX(a.scorings)
            const latestScoringB = firstX(b.scorings)
            return latestScoringB.id - latestScoringA.id
        })
        const oldestScoringId = _.minBy(scorings, v => v.id)?.id
        return [rows, oldestScoringId]
    }, [applicationDict, scoringDict])

    const columns = useMemo<BaseTableColumn<ClientRow>[]>(() => {
        return [
            {
                id: 'live',
                header: 'Live',
                render: v => <LiveIndicator clientId={v.clientId} />,
            },
            {
                id: 'latest-event',
                header: 'Latest event',
                render: v => formatDateTime(firstX(v.scorings)?.created),
            },
            {
                id: 'scoring-count',
                header: 'Scorings',
                render: v => v.scorings.length,
            },
            {
                id: 'form-open-count',
                header: 'Contact form opened',
                render: v => v.scorings.filter(s => s.contact_form_opened).length,
            },
            {
                id: 'application-count',
                header: 'Applictions sent',
                render: v => v.applications.length,
            },
        ]
    }, [])

    return (
        <Stack direction="column" spacing={2} useFlexGap>
            <PageTitleRow title="Scorings" />
            <BaseTable
                columns={columns}
                rows={rows}
                size="small"
                uniqueRowId={v => v.clientId}
                //rowProps={v => ({ onClick: () => setSelected(v), sx: { cursor: 'pointer' } })}
            />
            <AsyncButton
                callFn={() => loadMore.callFn(oldestScoringId)}
                processing={loadMore.processing}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                ml={2}
            >
                Load more!
            </AsyncButton>
        </Stack>
    )
}

interface ClientRow {
    clientId: string
    scorings: Scoring[]
    applications: Application[]
}

interface LiveIndicatorProps {
    clientId: string
}
function LiveIndicator({ clientId }: LiveIndicatorProps) {
    const precense = useAppSelector(state => state.presence.presence[clientId])
    const delta = precense?.ts ? Math.abs(unixTime() - precense.ts) : Number.MAX_SAFE_INTEGER
    let color: string = '#d1d1d1'
    if (delta < 60) {
        color = '#04d304'
    } else if (delta < 120) {
        color = '#fff568'
    }
    return (
        <div
            style={{ width: '20px', height: '20px', borderRadius: '15px', backgroundColor: color }}
        ></div>
    )
}
