import React, { useCallback, useRef } from 'react'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import { Remove as RemoveIcon, Translate as TranslateIcon } from '@mui/icons-material'

import * as yup from '@a10base/common/yup/index.js'

import { GlobalNotification, TranslationDict } from '@a10base/common/types/index.js'
import {
    DatetimeLocal,
    FormErrorSummary,
    Spinner,
    SaveButton,
    RemoveButtonWithConfirmation,
    PageTitleRow,
    PopupMenuRef,
    PopupMenu,
} from '@a10base/frontend/components/index.js'
import { navigateTo, serverData, trpcBase } from '@a10base/frontend/util/index.js'
import {
    useAsyncFn1Param,
    useAsyncFn3,
    useDeleteItemBase,
    useFormData,
    useItemAdmin,
    useUpsertItemsBase,
    useValidatedAsyncFn,
} from '@a10base/frontend/hooks/index.js'
import { jsonbToObjectX } from '@a10base/common/db.js'

interface NotificationEditProps {
    id: number
}

export const NotificationEdit: React.FC<NotificationEditProps> = ({ id }) => {
    const [notification, loading] = useItemAdmin('global_notification', id)

    if (loading) {
        return <Spinner spinning />
    }
    if (!notification) {
        return <Typography variant="body1">Not found</Typography>
    }
    return <GlobalNotificationEditForm notification={notification} />
}

interface GlobalNotificationEditFormProps {
    notification: GlobalNotification
}
export const GlobalNotificationEditForm: React.FC<GlobalNotificationEditFormProps> = ({
    notification,
}) => {
    const upsert = useUpsertItemsBase('global_notification')
    const { values, setValue, validate, errors, dirty, clearDirty } = useFormData(
        yup.updateGlobalNotificationData,
        {
            ...notification,
            translations: jsonbToObjectX<TranslationDict>(notification.translations),
        }
    )

    const onSuccess = useCallback(
        (updated: GlobalNotification[]) => {
            upsert(updated)
            clearDirty()
        },
        [upsert, clearDirty]
    )

    const update = useAsyncFn1Param(
        trpcBase.admin.globalNotifications.updateNotification.mutate,
        { id: notification.id, data: values },
        onSuccess
    )
    const validateAndUpdate = useValidatedAsyncFn(update, validate)

    return (
        <Stack direction="column" spacing={1} useFlexGap>
            <PageTitleRow title="Notification" />
            <Typography variant="h6" mt={3}>
                Basic info
            </Typography>

            <Stack direction="column" spacing={2} useFlexGap alignItems="flex-start">
                <FormControl sx={{ minWidth: '200px' }}>
                    <InputLabel id="type-selector-label">Type</InputLabel>
                    <Select
                        labelId="type-selector-label"
                        value={values.type}
                        onChange={e => setValue('type', e.target.value)}
                        label="Type"
                    >
                        <MenuItem value="info">Info</MenuItem>
                        <MenuItem value="alert">Alert</MenuItem>
                    </Select>
                </FormControl>

                <FormGroup>
                    <Stack direction="row" spacing={1} useFlexGap>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.show_in_site}
                                    onChange={e => setValue('show_in_site', e.target.checked)}
                                />
                            }
                            label="Show on site"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.show_in_app}
                                    onChange={e => setValue('show_in_app', e.target.checked)}
                                />
                            }
                            label="Show on app"
                        />
                    </Stack>
                </FormGroup>

                <Stack direction="row" spacing={1} useFlexGap alignItems="center">
                    <DatetimeLocal
                        value={values.valid_from}
                        onChange={v => setValue('valid_from', v)}
                        label="Valid from"
                    />
                    <RemoveIcon sx={{ alignSelf: 'flex-end', marginBottom: '10px' }} />
                    <DatetimeLocal
                        value={values.valid_to}
                        onChange={v => setValue('valid_to', v)}
                        label="Valid to"
                    />
                </Stack>

                <Typography variant="h6" mt={2}>
                    Message
                </Typography>

                <TranslationDictEditor
                    value={values.translations as TranslationDict}
                    onChange={v => setValue('translations', v)}
                />

                <FormErrorSummary errors={errors} />
            </Stack>

            <Stack direction="row" mt={3} justifyContent="space-between">
                <SaveButton {...validateAndUpdate} disabled={!dirty} size="medium" />
                <DeleteButton notification={notification} />
            </Stack>
        </Stack>
    )
}
interface DeleteButtonProps {
    notification: GlobalNotification
}
function DeleteButton({ notification }: DeleteButtonProps) {
    const deleteItem = useDeleteItemBase('global_notification', notification.id)

    const remove = useCallback(async () => {
        await trpcBase.admin.globalNotifications.delete.mutate({ id: notification.id })
        deleteItem()
        navigateTo('/notifications')
    }, [notification.id, deleteItem])

    return (
        <RemoveButtonWithConfirmation
            removeFn={remove}
            modalBody={'Are you sure you want to delete notification?'}
        />
    )
}

interface TranslationDictEditorProps {
    value: TranslationDict
    onChange: (value: TranslationDict) => void
}
function TranslationDictEditor({ value, onChange }: TranslationDictEditorProps) {
    const translate = useAsyncFn3(
        async (content: string, sourceLang: string, targetLangs: string[]) => {
            const translations = await trpcBase.admin.translation.translate.mutate({
                content,
                sourceLangCode: sourceLang,
                targetLangCodes: targetLangs,
                type: 'html',
            })
            onChange({ ...translations, [sourceLang]: content })
        }
    )

    return (
        <Stack direction="column" spacing={2} useFlexGap width="100%">
            {serverData.languages.map(lang => (
                <Stack direction="row" spacing={1} useFlexGap key={lang.code}>
                    <TextField
                        disabled={translate.processing}
                        label={lang.english_name}
                        value={value[lang.code] ?? ''}
                        onChange={e => onChange({ ...value, [lang.code]: e.target.value })}
                        fullWidth
                    />
                    <TranslationMenuButton
                        langCode={lang.code}
                        translations={value}
                        processing={translate.processing}
                        translate={translate.callFn}
                    />
                </Stack>
            ))}
        </Stack>
    )
}
interface TranslationMenuButtonProps {
    langCode: string
    translations: TranslationDict
    processing: boolean
    translate: (content: string, sourceLang: string, targetLangs: string[]) => void
}
function TranslationMenuButton({
    langCode,
    translations,
    processing,
    translate,
}: TranslationMenuButtonProps) {
    const popupRef = useRef<PopupMenuRef | null>(null)

    if (serverData.languages.length <= 1) {
        return null
    }

    return (
        <PopupMenu ref={popupRef} icon={<TranslateIcon />} disabled={processing}>
            {serverData.languages.length > 2 && (
                <MenuItem>
                    <Button
                        disabled={processing}
                        onClick={() => {
                            const content = translations[langCode]
                            if (content) {
                                translate(
                                    content,
                                    langCode,
                                    serverData.languages.map(v => v.code)
                                )
                                popupRef.current?.close()
                            }
                        }}
                    >
                        Translate to all other languages
                    </Button>
                </MenuItem>
            )}
            {serverData.languages
                .filter(v => v.code !== langCode)
                .map(v => {
                    return (
                        <MenuItem key={v.code}>
                            <Button
                                disabled={processing}
                                onClick={() => {
                                    const content = translations[langCode]
                                    if (content) {
                                        translate(content, langCode, [v.code])
                                        popupRef.current?.close()
                                    }
                                }}
                            >
                                Translate to&nbsp;
                                <strong>
                                    {' '}
                                    {v.english_name} ({v.code})
                                </strong>
                            </Button>
                        </MenuItem>
                    )
                })}
        </PopupMenu>
    )
}
