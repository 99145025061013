import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

import { formatPersonName } from '@a10yll/common/index.js'
import { Spinner } from '@a10base/frontend/components/index.js'
import { useItemAdmin, usePerson } from '@a10yll/frontend/hooks/index.js'

interface PersonNameProps extends TypographyProps {
    pid: number | undefined | null
}
export function PersonName(props: PersonNameProps) {
    const { pid, ...typoProps } = props
    const person = usePerson(pid)
    if (!pid) {
        return null
    }
    if (!person) {
        return <PersonNameWithLoading {...props} />
    }
    return <Typography {...typoProps}>{formatPersonName(person)}</Typography>
}

function PersonNameWithLoading({ pid, ...typoProps }: PersonNameProps) {
    const [person] = useItemAdmin('person', pid, { ignoreError: true, noLoadIfExists: true })
    return (
        <Typography {...typoProps}>
            {person ? formatPersonName(person) : <Spinner spinning />}
        </Typography>
    )
}
