import React from 'react'
import { Stack, Typography } from '@mui/material'
import { LineChart } from '@mui/x-charts/LineChart'

import { PageTitleRow } from '@a10base/frontend/components/index.js'
import { usePollingLoader } from '@a10base/frontend/hooks/loader.js'
import { trpc } from '@a10yll/frontend/util/index.js'

export function AdminDashboardPage() {
    return (
        <Stack direction="column" spacing={1} useFlexGap>
            <PageTitleRow title="Dashboard" />
            <Stats />
        </Stack>
    )
}

function Stats() {
    const [stats, loading] = usePollingLoader(() => trpc.admin.scoring.loadStats.query({}), 3600)
    if (!stats && loading) {
        return <Typography>Loading ...</Typography>
    }
    if (stats === undefined) {
        return <Typography>No data :(</Typography>
    }
    const days = stats.map(v => new Date(v.date)) ?? []
    return (
        <Stack direction="column" spacing={1} useFlexGap>
            <LineChart
                xAxis={[{ data: days, label: 'Date', scaleType: 'time' }]}
                yAxis={[{ label: 'Count', min: 0, scaleType: 'linear' }]}
                series={[
                    {
                        label: 'Unique clients',
                        data: stats.map(v => v.clientIdCount),
                    },
                    {
                        label: 'Contact form openings',
                        data: stats.map(v => v.formOpenCount),
                    },
                    {
                        label: 'Sent applications',
                        data: stats.map(v => v.applicationCount),
                    },
                ]}
                width={1000}
                height={300}
            />
            <LineChart
                xAxis={[{ data: days, label: 'Date', scaleType: 'time' }]}
                yAxis={[{ label: 'Count', min: 0, scaleType: 'linear' }]}
                series={[
                    {
                        label: 'Scoring calculations',
                        data: stats.map(v => v.scoringCount),
                    },
                ]}
                width={1000}
                height={200}
            />
        </Stack>
    )
}
