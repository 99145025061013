import React, { useMemo } from 'react'
import _ from 'lodash'

import { useLoader } from '@a10base/frontend/hooks/index.js'

import {
    BaseTableColumn,
    ExternalLink,
    Table,
    useTableState,
} from '@a10base/frontend/components/index.js'
import { trpcBase } from '@a10base/frontend/util/trpc.js'
import { serverData } from '@a10base/frontend/util/server-data.js'

const cacheOpts = { secs: 600, key: 'static-html' }

export function StaticHtmlPage() {
    const [filePaths, loading] = useLoader(
        () => trpcBase.admin.misc.getStaticHtmlFiles.query({ target: 'www' }),
        undefined,
        { cache: cacheOpts }
    )
    const tableState = useTableState(50)

    const rows = useMemo(() => {
        const rows: HtmlPage[] = []
        if (!filePaths) return rows
        const groupedByBasePath = _.groupBy(filePaths, v => {
            const i = v.lastIndexOf('/')
            return i === -1 ? '' : v.slice(0, i)
        })
        for (const [basePath, files] of Object.entries(groupedByBasePath)) {
            if (basePath === '') continue
            const js = files.some(v => v.endsWith('.js'))
            const less = files.some(v => v.endsWith('.less'))
            const langs = files.filter(v => v.endsWith('.html')).map(v => v.slice(-7, -5))
            rows.push({ name: basePath, js, less, langs })
        }
        rows.sort((a, b) => a.name.localeCompare(b.name))
        return rows
    }, [filePaths])

    const columns = useMemo<BaseTableColumn<HtmlPage>[]>(() => {
        return [
            {
                id: 'name',
                header: 'Name',
                render: v => v.name,
            },
            {
                id: 'js',
                header: 'js',
                render: v => (v.js ? '✓' : ''),
            },
            {
                id: 'less',
                header: 'less',
                render: v => (v.js ? '✓' : ''),
            },
            {
                id: 'langs',
                header: 'Languages',
                render: v => v.langs.join(', '),
            },
            {
                id: 'action',
                header: '',
                render: v => {
                    const { SITE_URL, A10_ENV, CLIENT_LANG } = serverData.config
                    let url = `${SITE_URL}/${v.name}?forcedLang=${CLIENT_LANG}&noCache=true`
                    if (A10_ENV === 'local') {
                        url = url + '&temp-target=www'
                    }
                    return <ExternalLink href={url}>Render</ExternalLink>
                },
            },
        ]
    }, [])

    return (
        <div>
            <h1>Html pages</h1>
            <Table rows={rows} loadingRows={loading} columns={columns} {...tableState} />
        </div>
    )
}

interface HtmlPage {
    name: string
    js: boolean
    less: boolean
    langs: string[]
}
