import React, { useEffect, useRef } from 'react'
import { CssBaseline /*useMediaQuery*/ } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'

// https://mui.com/x/react-date-pickers/getting-started/
// import { LocalizationProvider } from '@mui/x-date-pickers'
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { defaultThemeOverrides } from '@a10base/frontend/theme.js'
import {
    ErrorBoundary,
    FlashNotificationViewer,
    Router,
} from '@a10base/frontend/components/index.js'
import { routes } from './routes.js'
import { useInterval } from '@a10base/frontend/hooks/index.js'
import { useAppDispatch, useAppSelector } from '@a10yll/frontend/redux/redux-hooks.js'
import { unixTime } from '@a10base/common/misc.js'
import { handleErrorAndNotify, trpcBase } from '@a10base/frontend/util/index.js'
import { useJwt } from '../../hooks/misc.js'
import { itemActions, presenceActions } from '../../redux/index.js'
import { AdminPageWrapper } from './components/index.js'
import { Person } from '@a10base/common/index.js'
import { Code, TestCase } from '@a10yll/common/index.js'

const mainDataReloadIntervalSec = 30 * 60 // 30 minutes

const theme = createTheme({
    ...deepmerge(defaultThemeOverrides, {
        palette: {
            primary: {
                main: '#022bb8',
            },
        },
        typography: {
            fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
        },
    }),
})

// CssBaseline docs: https://mui.com/material-ui/react-css-baseline/
const cssBaseline = <CssBaseline />

export const Application: React.FC = () => {
    const jwt = useJwt()
    const online = useAppSelector(state => state.context.online)
    const visible = useAppSelector(state => state.context.visible)
    const pathname = useAppSelector(state => state.url.pathname)
    const nextDataReloadAt = useRef<number>(unixTime() + mainDataReloadIntervalSec)
    const reloadCheck = useInterval(2 * 60 * 1000, 'main data reload check') // 2 minutes
    const presenceCheck = useInterval(30 * 1000, 'presence check') // 30 seconds
    const dispatch = useAppDispatch()

    useEffect(() => {
        async function loadData() {
            const persons = (await trpcBase.admin.item.getItems.query({
                table: 'person',
            })) as Person[]
            const codes = (await trpcBase.admin.item.getItems.query({ table: 'code' })) as Code[]
            const testCases = (await trpcBase.admin.item.getItems.query({
                table: 'test_case',
            })) as TestCase[]
            dispatch(itemActions.upsertItems('person', persons))
            dispatch(itemActions.upsertItems('code', codes))
            dispatch(itemActions.upsertItems('test_case', testCases))
            nextDataReloadAt.current = unixTime() + mainDataReloadIntervalSec
        }
        const timeToReloadData = nextDataReloadAt.current < unixTime()
        if (online && visible && timeToReloadData && jwt?.pid) {
            loadData().catch(error => handleErrorAndNotify(error))
        }
    }, [online, visible, reloadCheck, dispatch, jwt?.pid])

    // Refresh presences every 2 minutes
    useEffect(() => {
        dispatch(presenceActions.refreshPresences())
    }, [dispatch, presenceCheck])

    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                {cssBaseline}
                <FlashNotificationViewer />
                <Router pathname={pathname} routes={routes} DefaultWrapper={AdminPageWrapper} />
            </ThemeProvider>
        </ErrorBoundary>
    )
}
