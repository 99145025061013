import React, { ReactNode, useMemo } from 'react'
import { Chip, Stack } from '@mui/material'
import { startOfHour } from 'date-fns'
import { Add as CreateNewIcon } from '@mui/icons-material'

import { TranslationDict, type GlobalNotification } from '@a10base/common/types/index.js'

import { getIdOrThrow, truncate } from '@a10base/common/misc.js'
import {
    AsyncButton,
    BaseTableColumn,
    GetSearchableValuesFn,
    PageTitleRow,
    Table,
    useTableState,
} from '@a10base/frontend/components/index.js'
import { formatDateTime, navigateTo, serverData, trpcBase } from '@a10base/frontend/util/index.js'
import { useAsyncFn, useItemsAdmin, useUpsertItemsBase } from '@a10base/frontend/hooks/index.js'
import { jsonbToObjectX } from '@a10base/common/db.js'

const initialTranslationsDict: TranslationDict = serverData.languages.reduce<TranslationDict>(
    (acc, lang) => {
        acc[lang.code] = `Message in ${lang.english_name} ...`
        return acc
    },
    {}
)

const defaultLang = serverData.languages[0]

export const NotificationList: React.FC = () => {
    const [notifications, loadingNotifications] = useItemsAdmin('global_notification', '', {
        sorterFn: notificationSorterFn,
    })
    const tableState = useTableState()
    const upsert = useUpsertItemsBase('global_notification')
    const createNew = useAsyncFn(
        () =>
            trpcBase.admin.globalNotifications.createNotification.mutate({
                show_in_app: false,
                show_in_site: false,
                type: 'info',
                valid_from: startOfHour(new Date()).toISOString(),
                valid_to: startOfHour(new Date()).toISOString(),
                translations: initialTranslationsDict,
            }),
        notification => {
            upsert([notification])
            navigateTo(notification.id)
        }
    )

    const columns = useMemo<BaseTableColumn<GlobalNotification>[]>(
        () => [
            {
                id: 'active',
                render: v => renderActivity(v),
            },
            {
                id: 'name',
                render: v =>
                    truncate(jsonbToObjectX<TranslationDict>(v.translations)[defaultLang.code], 32),
            },
            { id: 'type', render: v => v.type },
            { id: 'target', render: v => renderNotificationTarget(v) },
            {
                id: 'valid from',
                render: v => formatDateTime(v.valid_from),
            },
            {
                id: 'valid to',
                render: v => formatDateTime(v.valid_to),
            },
        ],
        []
    )
    return (
        <Stack direction="column" spacing={2} useFlexGap>
            <PageTitleRow title="Notifications">
                <AsyncButton
                    {...createNew}
                    disabled={loadingNotifications}
                    startIcon={<CreateNewIcon />}
                >
                    Create new
                </AsyncButton>
            </PageTitleRow>
            <Table
                size="small"
                columns={columns}
                rows={notifications}
                {...tableState}
                uniqueRowId={getIdOrThrow}
                loadingRows={loadingNotifications}
                getSearchableValues={getSearchableValues}
                rowProps={v => ({ onClick: () => navigateTo(v.id), sx: { cursor: 'pointer' } })}
            />
        </Stack>
    )
}

const getSearchableValues: GetSearchableValuesFn<GlobalNotification> = v => {
    const translations = jsonbToObjectX<TranslationDict>(v.translations)
    return Object.values(translations)
}

function notificationSorterFn(n1: GlobalNotification, n2: GlobalNotification): number {
    const now = new Date().toISOString()
    const n1Active = n1.valid_from <= now && n1.valid_to >= now
    const n2Active = n2.valid_from <= now && n2.valid_to >= now
    if (n1Active && !n2Active) {
        return -1
    }
    if (!n1Active && n2Active) {
        return 1
    }
    const n1Scheduled = n1.valid_from > now
    const n2Scheduled = n2.valid_from > now
    if (n1Scheduled && !n2Scheduled) {
        return -1
    }
    if (!n1Scheduled && n2Scheduled) {
        return 1
    }
    return n1.valid_to < n2.valid_to ? -1 : 1
}

function renderActivity(notification: GlobalNotification): ReactNode {
    const yellow = '#fbfea3'
    const green = '#a3fea9'
    const red = '#fea3a3'
    const now = new Date().toISOString()
    if (notification.valid_from > now) {
        return <Chip sx={{ backgroundColor: yellow }} label="Scheduled" />
    }
    if (notification.valid_to < now) {
        return <Chip sx={{ backgroundColor: red }} label="Expired" />
    }
    return <Chip sx={{ backgroundColor: green }} label="Active" />
}

function renderNotificationTarget(notification: GlobalNotification): string {
    const targets: string[] = []
    if (notification.show_in_app) {
        targets.push('app')
    }
    if (notification.show_in_site) {
        targets.push('site')
    }
    if (targets.length === 0) {
        return '[none]'
    }
    return targets.join(', ')
}
