import React, { useCallback, useEffect, useMemo } from 'react'
import { List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import {
    // Email as EmailTemplateIcon,
    Announcement as NotificationIcon,
    // Forest as LogsIcon,
    EggAlt as MiscIcon,
    CloudDownload as PublicAssetsIcon,
    Dashboard as DashboardIcon,
    //Public as WwwIcon,
    Code as CodeIcon,
    Checklist as TestCaseIcon,
    Euro as EuroIcon,
} from '@mui/icons-material'

import { AppSkeleton } from '@a10base/frontend/components/index.js'
import { navigateTo } from '@a10base/frontend/util/index.js'
import { useAppSelector } from '../../../redux/redux-hooks.js'
import { isA10Admin } from '@a10base/common/permissions.js'
import { ProfileMenu } from './ProfileMenu.js'

interface AdminPageWrapperProps {
    children: React.ReactNode
}
export const AdminPageWrapper: React.FC<AdminPageWrapperProps> = ({ children }) => {
    const pathname = useAppSelector(state => state.url.pathname)
    const jwt = useAppSelector(state => state.auth.jwt)

    useEffect(() => {
        if (!jwt || !isA10Admin(jwt)) {
            navigateTo('/login')
        }
    }, [jwt, pathname])

    const menu = useCallback(
        (onlyIcons: boolean) => {
            return (
                <List component="nav" sx={{ pt: 0, width: '100%' }}>
                    <MenuItem
                        path="/"
                        icon={<DashboardIcon />}
                        onlyIcon={onlyIcons}
                        text="Dashboard"
                        pathname={pathname}
                    />
                    <MenuItem
                        path="/code"
                        icon={<CodeIcon />}
                        onlyIcon={onlyIcons}
                        text="Code"
                        pathname={pathname}
                    />
                    <MenuItem
                        path="/test-cases"
                        icon={<TestCaseIcon />}
                        onlyIcon={onlyIcons}
                        text="Test cases"
                        pathname={pathname}
                    />
                    <MenuItem
                        path="/scorings"
                        icon={<EuroIcon />}
                        onlyIcon={onlyIcons}
                        text="Scorings"
                        pathname={pathname}
                    />
                    <MenuItem
                        path="/public-assets"
                        icon={<PublicAssetsIcon />}
                        onlyIcon={onlyIcons}
                        text="Public assets"
                        pathname={pathname}
                    />
                    <MenuItem
                        path="/notifications"
                        icon={<NotificationIcon />}
                        onlyIcon={onlyIcons}
                        text="Notifications"
                        pathname={pathname}
                    />
                    <MenuItem
                        path="/misc"
                        icon={<MiscIcon />}
                        onlyIcon={onlyIcons}
                        text="Misc"
                        pathname={pathname}
                    />
                </List>
            )
        },
        [pathname]
    )

    const appBarRigth = useMemo(() => {
        return (
            <>
                <ProfileMenu />
            </>
        )
    }, [])
    return (
        <AppSkeleton
            appBarRigth={appBarRigth}
            menu={menu}
            appBarSx={{ backgroundColor: '#0047ff', color: 'white' }}
            sideBarSx={{ backgroundColor: '#e7edff' }}
        >
            {children}
        </AppSkeleton>
    )
}

interface MenuItemProps {
    path: string
    icon: React.ReactNode
    onlyIcon?: boolean
    text: string
    pathname: string
}
const MenuItem: React.FC<MenuItemProps> = ({ path, icon, onlyIcon, text, pathname }) => {
    return (
        <ListItemButton
            onClick={() => navigateTo(path)}
            selected={pathname.startsWith(path)}
            sx={{ minHeight: '32px' }}
        >
            {onlyIcon ? (
                <Tooltip title={text} placement="right">
                    <ListItemIcon sx={{ mb: 0.5 }}>{icon}</ListItemIcon>
                </Tooltip>
            ) : (
                <>
                    <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                </>
            )}
        </ListItemButton>
    )
}
